import React from 'react'
import styled from '@emotion/styled'

import bookmarkImg from '../images/bookmark.svg'

const Wrapper = styled.div`
    
`
const Heading = styled.h1`
    font-size: 5em;
    line-height: calc(70/80);
    letter-spacing: -2.22px;
    font-weight: 500;
    margin-top: 3rem;
    font-family: var(--f-secondary);
    @media screen and (max-width: 767px){
        font-size: 4em;
    }
    > span{
        color: var(--c-tertiary);
    }
`
const Reading = styled.div`
    margin-top: 3rem;
    border: 1px solid rgba(0,0,0, 0.1);
    padding: 1rem;
    position: relative;
    border-radius: 3px;
    &:after{
        content: "";
        display: block;
        width: 10px;
        height: 18px;
        background-image: url(${bookmarkImg});
        background-repeat: no-repeat;
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }
    h4{
        font-size: 0.75em;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1;
    }
`
const ReadingItems = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`
const ReadingItem = styled.li`
    display: flex;
    > img{
        width: 70px;
        margin-right: 1rem;
        border: 1px solid rgba(0,0,0, 0.1);
    }
`
const ReadingItemText = styled.div`
    flex-basis: calc(100% - 86px);
    h5{
        font-weight: normal;
        font-size: 16px;
        > span{
            display: block;
            line-height: 1.35;
            &:first-child{
                font-weight: 500;
                font-size: 0.75em;
                font-family: var(--f-secondary);
            }
            &:last-child{
                font-size: 0.625em;
                margin-top: 1rem;
            }
        }
    }
`

const Sidebar = (props) => {
    return(
        <Wrapper>
            <Heading>
                {props.headingText}<span>.</span></Heading>
            <Reading>
                <h4>Currently Reading</h4>
                <ReadingItems>
                    <ReadingItem>
                        <img src="https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1569345815l/50776459._SX318_SY475_.jpg" alt="Shape Up"/>
                        <ReadingItemText>
                            <h5>
                                <span>Shape Up: Stop Running in Circles and Ship Work that Matters</span>
                                <span>by Ryan Singer, Jason Fried</span>
                            </h5>
                        </ReadingItemText>
                    </ReadingItem>
                </ReadingItems>
            </Reading>
        </Wrapper>
    )
}

export default Sidebar