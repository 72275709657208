import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Sidebar from '../components/sidebar'

import styled from '@emotion/styled'

const Container = styled.div`
	padding-left: 0;
	padding-right: 0;
	@media screen and (max-width: 1023px){
		padding-left: 1rem;
		padding-right: 1rem;
	}
`
const SidebarCol = styled.div`
	grid-column: 2 / span 3;
	@media screen and  (max-width: 1023px){
		display: none;
	}
`
const NowWrapper = styled.div`
	grid-column: 6 / span 6;
	grid-row: 1 / 4;
	@media screen and (max-width: 767px){
		grid-column: 1 / span 4;
	}
	@media screen and (min-width: 768px) and (max-width: 1023px){
		grid-column: 2 / span 6;
	}
	h3{
		margin-top: 2rem;
		margin-bottom: 1rem;
	}
	p, ul{
		margin-bottom: 1rem;
	}
	a{
		color: var(--c-tertiary);
		font-weight: 500;
	}
	hr{
		margin: 2rem 0;
	}
`

const descPage = 'Here’s what I’m currently working on (last updated Oct 18, 2020)'

const NowPage = (props) => {
	return (
		<Layout>
					<SEO 
						pageTitle="What I'm doing right now"
						pageDesc={descPage} />
						<Container className="container">
							<SidebarCol><Sidebar headingText="Work, life, now" /></SidebarCol>
							<NowWrapper>
								<p>
								{descPage}:
								</p>
							
								<h3>Personal</h3>
								<ul>
									<li>Being husband of Raras and father of Ramona</li>
									<li>
										Books I'm reading:
										<ul>
											<li>Shape Up by Ryan Singer, Jason Fried</li>
										</ul>
									</li>
									<li>Home quarantine since March 2020</li>
								</ul>

								<h3>Full time work <span role="img" aria-label="Starting a trial work">🚀</span></h3>
								<p>
									I'm currently working as fulltime designer at <a href="https://latecheckout.studio" target="_blank">LateCheckout</a>.
								</p>

								<h3>Freelance work</h3>
								<p> — </p>

								<h3>Side project</h3>
								<ul>
									<li>Building <a href="https://www.figma.com/community/file/865974955094967251/Boxframey" rel="noopener noreferrer">Boxframey</a> for dashboard.</li>	
								</ul>

								<h3>Volunteer</h3>
								<p>Working on <a href="https://latestprivacy.org" rel="noopener noreferrer">latestprivacy.org</a> as front-end developer.</p>

								<hr/>
								<p>This list is inspired by <a href="https://tyler.io/now/" target="blank" rel="noopener noreferrer">Tyler Hall</a>, who was inspired by <a href="https://mattgemmell.com/now/" target="blank" rel="noopener noreferrer">Matt Gemmell</a>, who was inspired by <a href="https://sivers.org/nowff" target="blank" rel="noopener noreferrer">Derek Sivers</a>.</p>
							</NowWrapper>
				
			</Container> 
		</Layout>
	)
}

export default NowPage
